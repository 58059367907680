import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import { FaChevronDown } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Navigation = () => {
  const [categories, setCategories] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axios
      .get("https://api.themoviedb.org/3/genre/movie/list?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US")
      .then((res) => {
        setCategories(res.data.genres);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        var navLinks = document.querySelectorAll('.navigation a, .navigation .right');
        navLinks.forEach(function(link) {
          link.style.display = '';
        });
      }
    };

    const hamburgerMenu = document.querySelector('.hamburger-menu');
    hamburgerMenu.addEventListener('click', function() {
      var navLinks = document.querySelectorAll('.navigation a, .navigation .right');
      navLinks.forEach(function(link) {
        link.style.display = (link.style.display === 'none' || link.style.display === '') ? 'block' : 'none';
      });
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const handleCategoryClick = (categoryId) => {
    history.push({
      pathname: "/categories",
      state: { selectedCategory: categoryId },
    });
    setDropdownOpen(false);
  };


  return (
    <div className="navigation">
      <div className="hamburger-menu"> {/* Ajout du bouton hamburger */}
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="nav-links">
        <NavLink exact to="/" activeClassName="nav-active">
          Home
        </NavLink>
        <div className="dropdown">
          <NavLink exact to="#" activeClassName="nav-active" onClick={() => setDropdownOpen(!dropdownOpen)}>
            Categories <FaChevronDown />
          </NavLink>
          {dropdownOpen && (
            <div className="dropdown-content">
              {categories.map((category) => (
                <div key={category.id} onClick={() => handleCategoryClick(category.id)}>
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <NavLink exact to="/actor" activeClassName="nav-active">
          Actor
        </NavLink>
        <NavLink exact to="/about" activeClassName="nav-active">
          About
        </NavLink>
        <NavLink exact to="/search" activeClassName="nav-active">
          Search
        </NavLink>
      </div>
      <div className="social-icons"> 
        <FontAwesomeIcon className="social-icon" icon={faFacebookF} color="currentColor" /> 
        <FontAwesomeIcon className="social-icon" icon={faInstagram} color="currentColor" /> 
        <FontAwesomeIcon className="social-icon" icon={faTwitter} color="currentColor" /> 
        <FontAwesomeIcon className="social-icon" icon={faYoutube} /> 
      </div>
    </div>
  );
}

export default Navigation;
