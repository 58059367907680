import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Logo = () => {
  const history = useHistory();

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
  
    history.push(`/search?query=${searchTerm}`);
  }

  return (
    <div className="logo">
      {/* wrap img with Link */}
      <Link to="/">
        <img src="../images/Adrenaline.png" alt="logo" />
      </Link>
      <div className="search-wrapper">
        {/* Including font awesome for search icon */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        <div className="input-container">
          {/* Search Icon */}
          <i className="fa fa-search search-icon"></i>
          {/* Add an event handler for search with styled input field */}
          <input type="text" className="search-input" placeholder="Search" onChange={handleSearch} />
        </div>
        {/* Add Search Button next to the search box */}
        <button className="search-button">Search</button>
      </div>
      <div className="auth-links">
        <NavLink to="/login" activeClassName="nav-active" className="red-background">
          Connexion
        </NavLink>
        <NavLink to="/register" activeClassName="nav-active" className="red-background">
          Inscription
        </NavLink>
      </div>
    </div>
  );
}

export default Logo;
