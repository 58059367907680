// Importations des bibliothèques et des composants nécessaires
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Acceuil from "./pages/Acceuil";
import Search from "./pages/Search";
import Categories from "./pages/Categories";
import About from "./pages/About";
import MovieDetails from "./pages/MovieDetails";
import ActorDetails from "./pages/ActorDetails";
import ActorPage from "./pages/ActorPage";
import NotFound from "./pages/NotFound";


import Login from "./components/Login";
import Register from "./components/Register";

// Définition du composant App
const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Acceuil} />
        <Route path="/search" exact component={Search} />
        <Route path="/categories" exact component={Categories} />
        <Route path="/about" exact component={About} />

        // Ajoutez les deux lignes suivantes pour vérifier si les composants de connexion et d'inscription sont correctement chargés
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/movieDetails" exact component={MovieDetails} />
        <Route path="/actor" exact component={ActorPage} />
        <Route path="/actorDetails" exact component={ActorDetails} />
        <Route path="/actorDetails/:id" exact component={ActorDetails} />
        <Route path="/actor/:id" exact component={ActorDetails} />

      
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}


export default App;