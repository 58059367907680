import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

// Composant Movie
const Movie = (props) => {
  const { movie, onClickMovie } = props;

  // Gérer les erreurs d'image
  const handleImageError = (event) => {
    event.target.src = 'https://placehold.co/235x350'; // Image de substitution
  };

  return (
    <div className="movie" onClick={onClickMovie}>
      <img className="movie-poster" src={`http://image.tmdb.org/t/p/original${movie.poster_path}`} alt="poster" onError={handleImageError} />
      <div className="conteneur">
        <li>{movie.title}</li>
      </div>
    </div>
  );
};

// Composant CategoriesMovies
const CategoriesMovies = () => {
  const location = useLocation();
  const history = useHistory();
  const [movies, setMovies] = useState([]);

  const selectedCategory = location.state?.selectedCategory;

  // Récupérer les films de la catégorie sélectionnée
  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(
          `https://api.themoviedb.org/3/discover/movie?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US&with_genres=${selectedCategory}`
        )
        .then((res) => setMovies(res.data.results));
    }
  }, [selectedCategory]);

  // Rediriger vers la page de détails du film
  const onClickMovie = (id) => {
    history.push('/movieDetails', { id });
  };

  return (
    <div className="categoriesMovies">
      {selectedCategory ? (
        <ul className="categoriesMovies-list">
          {movies.map((movie) => (
            <Movie
              movie={movie}
              key={movie.id}
              onClickMovie={() => onClickMovie(movie.id)}
            />
          ))}
        </ul>
      ) : (
        <div className="placeholder-container">
          <h2>Rien à afficher, veuillez sélectionner une catégorie</h2>
        </div>
      )}
    </div>
  );
};

export default CategoriesMovies;
