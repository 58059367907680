// Importation des bibliothèques nécessaires
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import Movie from "./Movie";
import Actor from "./Actor";  

const SearchMovie = () => {
    // Déclaration des états
    const [movieData, setMovieData] = useState([]);
    const [actorData, setActorData] = useState([]);
    const [query, setQuery] = useState("");
    const location = useLocation();
    const history = useHistory();

    // Extraire le terme de recherche de l'URL
    const search = new URLSearchParams(location.search).get('query');

    useEffect(() => {
        // Mettre à jour l'état de la requête avec le terme de recherche extrait
        setQuery(search);
    }, [search]);

    useEffect(() => {
        // Vérifier si la page vient d'être chargée pour la première fois, si oui, récupérer les 45 films les plus populaires
        if (!query) {
            axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US&page=1`)
                .then((res) => {
                    setMovieData(res.data.results.slice(0, 9)); // Prendre les 15 premiers films
        
                    axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US&page=2`)
                        .then((res) => {
                            setMovieData(prev => [...prev, ...res.data.results.slice(0,9)]); // Ajouter les 15 films suivants
        
                            axios.get(`https://api.themoviedb.org/3/movie/popular?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US&page=3`)
                                .then((res) => {
                                    setMovieData(prev => [...prev, ...res.data.results.slice(0, 9)]); // Ajouter les 15 films suivants pour un total de 45 films
                                });
                        });
                });
        } else {
            // Faire une requête à l'API de films
            axios.get(`https://api.themoviedb.org/3/search/movie?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US&query=${query}`)
                .then((res) => setMovieData(res.data.results));

            // Faire une requête à l'API des acteurs
            axios.get(`https://api.themoviedb.org/3/search/person?api_key=2a9dbf4a6b379d6903604e787f490137&language=en-US&query=${query}`)
                .then((res) => setActorData(res.data.results));
        }
    }, [query]);

    // Gérer le clic sur le label pour effectuer une recherche
    const handleClick = (e) => {
        if (e.target.className === 'label') {
            setQuery(e.target.textContent);
        }
    };

    return (
        <div className="searchMovie" onClick={handleClick}>
            {/* Ajout de l'en-tête */}
            <h5 className="header">The ultimate movie experience</h5>
            {/* Ajout des labels */}
            <div className="label-container">
                <p className="label-home">Home</p>
                <p className="label">Action</p>
                <p className="label">Drame</p>
                <p className="label">Horreur</p>
                <p className="label">Comédie</p>
                <p className="label">Fantaisie</p>
                <p className="label">Animation</p>
                <p className="label">Mystère</p>
                <p className="label">Science-fiction</p>
                <p className="label">Thriller</p>
                <p className="label">Aventure</p>
                <p className="label">Crime</p>
                <p className="label">Biographie</p>
                <p className="label">Histoire</p>
            </div>
            <div className="input-container">
                <label className="search-label" htmlFor="search-input">Search</label>
                <input
                    id="search-input"
                    className="input"
                    placeholder="Search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <i className="fa fa-search search-icon-input"></i>
                <button className="search-button">
                    Search
                </button>
            </div>
            {query ? (
                <ul className="searchMovie-list">
                    {movieData.map((m) => (
                        <Movie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails', { id: m.id })} />
                    ))}
                    {actorData.map((a) => (
                        <Actor actor={a} key={a.id} onClickActor={() => history.push('/actorDetails', { id : a.id })} />
                    ))}
                </ul>
            ) : (
                <div className="searchMovie-list">
                    {movieData.map((m) => (
                        <Movie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails', { id: m.id })} />
                    ))}
                </div>
            )}
        </div>
    );
};

// Exportation du composant SearchMovie
export default SearchMovie;
