import React from "react";
const Movie = (props) => {
    const { movie, isCarousel, onClickMovie } = props;

    const handleImageError = (event) => {
        event.target.src = 'https://placehold.co/235x350'; // Chemin vers l'image de substitution
      };

    return (
        <div className="movie" onClick={onClickMovie}>
            <img className="movie-poster" src={`http://image.tmdb.org/t/p/original${movie.poster_path}`} alt="poster"  onError={handleImageError}/>
            <div className="conteneur">
                {/* Si isCarousel est vrai, afficher le titre comme un titre de niveau 1 sinon comme un élément de liste */}
                {isCarousel ? (
                    <div>
                        <h1>{movie.title}</h1>
                        <p>{movie.overview}</p>
                        <div style={{ display: 'flex' }}>
                            <p style={{ color: 'red', marginRight: '10px', fontWeight: 'bold' }}>{movie.genre_names[0]}</p>
                            <p>{new Date(movie.release_date).getFullYear()}</p>
                        </div>


                    </div>
                ) : (
                    <li>{movie.title}</li>
                )}
            </div>
        </div>
    )
}

export default Movie;