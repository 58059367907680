// Importation des bibliothèques nécessaires
import React from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import SearchMovie from "../components/SearchMovie";

// Création de la fonction Search
const Search = () => {
    return (
        <div className="search">
            <Logo />
            <Navigation />
            <SearchMovie />
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    )
}

// Exportation du composant Search
export default Search;
