// Importation de la bibliothèque React
import React, { useState, useEffect } from "react";

// Importation des icônes des médias sociaux depuis 'react-icons'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaArrowUp } from 'react-icons/fa';

// Déclaration du composant Footer
const Footer = () => {
    // Déclaration de l'état de visibilité du bouton
    const [isVisible, setIsVisible] = useState(false);

    // Déclaration de l'état de langue
    const [language, setLanguage] = useState('fr'); // Par défaut, la langue est le français

    // Fonction pour vérifier si le bouton doit être visible ou non
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Fonction pour changer la langue
    const toggleLanguage = () => {
        setLanguage(language === 'fr' ? 'en' : 'fr');
    };

    // Fonction pour ramener l'utilisateur en haut de la page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    // Ajout d'un écouteur d'événement pour le défilement
    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <h3>{language === 'fr' ? 'À propos' : 'About'}</h3>
                    <p>{language === 'fr' ? 'Contact' : 'Contact'}</p>
                    <p>{language === 'fr' ? 'Compte' : 'Account'}</p>
                    <p>{language === 'fr' ? 'Salle de presse' : 'Press Room'}</p>
                </div>
                <div className="footer-column">
                    <h3>{language === 'fr' ? "Téléchargement de l'application" : 'Download App'}</h3>
                    <p>{language === 'fr' ? 'Magasin' : 'Store'}</p>
                    <p>{language === 'fr' ? 'Termes et conditions' : 'Terms and Conditions'}</p>
                    <p>{language === 'fr' ? 'Politique de confidentialité' : 'Privacy Policy'}</p>
                </div>
                <div className="footer-column">
                    <h3>FAQ</h3>
                    <p>{language === 'fr' ? 'Carrières' : 'Careers'}</p>
                    <p>{language === 'fr' ? 'Catégories' : 'Categories'}</p>
                    <p>{language === 'fr' ? 'Blog' : 'Blog'}</p>
                </div>
            </div>
            <div className="footer-icons">
                <div className="social-icons">
                    <FaFacebook className="icon" />
                    <FaInstagram className="icon" />
                </div>
                <p style={{ textAlign: "center" }}>
                    {language === 'fr'
                        ? 'Copyright © 2023 Adrenaline Cinema Confidentialité et accessibilité'
                        : 'Copyright © 2023 Adrenaline Cinema Privacy & Accessibility'}
                </p>
                <div className="social-icons">
                    <FaYoutube className="icon" />
                    <FaTwitter className="icon" />
                </div>
            </div>
            <div className="language-toggle">
                <button onClick={toggleLanguage} className="toggle-button">
                    {language === 'fr' ? 'Français' : 'English'}
                </button>
            </div>
            {isVisible && (
                <div onClick={scrollToTop} className="scroll-to-top">
                    <FaArrowUp />
                </div>
            )}
        </div>
    )
}

// Exportation du composant Footer
export default Footer;
