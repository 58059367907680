import React from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import PopularMovies from "../components/PopularMovies";
import Carousel from "../components/Carousel";
import TopActors from "../components/TopActors"; 

// Définir le composant Acceuil
const Acceuil = () => {
    return (
        <div className="Acceuil">
            <Logo />
            <Navigation />
            <PopularMovies /><br></br>
            <h2>Upcoming</h2>
            <Carousel category="upcoming" />
            <h2>Action</h2>
            <Carousel category={28} />
            <TopActors className="acceuil-top-actors" /> {/* Utilisez le composant TopActors ici avec la classe 'acceuil-top-actors' */}
            <div className="main-banner">
                <div className="banner-text">
                    <h1>Unlimited movies, TV shows, and more</h1>
                    <p>Ready to watch? Enter your email to create or restart your membership</p>
                    <p>Watch anywhere. Cancel anytime.</p>
                </div>
                <div className="email-signup">
                    <input type="email" id="email" required />
                    <button>SIGN UP</button>
                </div>
            </div>
            <div className="compatibility-banner">
                <div className="compatibility-text">
                    <h1>We're<br></br> compatible.</h1>
                    <h2>Stream Adrenaline Cinema <br></br>
                    from just about any phone,<br></br> tablet, smart TV, gaming<br></br> consoles, or PC.</h2>
                    <button className="stream-now-button">Stream Now</button>
                </div>
                <div className="compatibility-image">
                    <img src="https://i.imgur.com/F90IXSs.png" alt="Compatible Devices" />
                </div>
            </div>
            <div className="subscribe-banner">
                <div className="subscribe-text">
                    <h1>Stay in the cinematic loop!</h1>
                    <div className="centered-text-Acceuil">
                        Be the first to know about the latest blockbusters,<br></br>
                        exclusive interviews with your favorite stars, and<br></br>
                        behind-the-scenes insights.
                        Subscribe to our movie<br></br>
                        newsletter today and get a front-row seat to all the <br></br>
                        action. Don't miss out on
                        the buzz! Sign up now and<br></br>
                        let the movie magic come to you.
                        <h1>Subscribe to our newsletter</h1>
                    </div>
                </div>
                <div className="subscribe-signup">
                    <input type="email" id="subscribeEmail" required />
                    <button>SUBSCRIBE</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Acceuil;
