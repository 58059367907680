import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Actor from './Actor';
import { useHistory } from "react-router-dom";

const TopActors = () => {
    const [actors, setActors] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/person/popular?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&page=1`)
        .then((res) => {
            setActors(res.data.results.slice(0, 6));
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    const onClickActor = (actor) => {
        history.push({
            pathname: `/actor/${actor.id}`,
            state: { id: actor.id }
        });
    }

    return (
        <div className="top-actors-container">
            <h3 className="title">TOP ACTORS/ACTRESSES</h3>
            <div className="actors">
                {actors.map((actor, index) => (
                    <div className="actor" key={index} onClick={() => onClickActor(actor)}>
                        <img className="actor-image" src={`https://image.tmdb.org/t/p/w500${actor.profile_path}`} alt={actor.name} />
                        <div className="actor-name">{actor.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TopActors;
