import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import Actor from "../components/Actor";
import { useHistory } from "react-router-dom";


const MovieDetails = (props) => {
    const id = props.location.state.id;
    const URL = `https://api.themoviedb.org/3/movie/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const URL_CREDITS = `https://api.themoviedb.org/3/movie/${id}/credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const URL_VIDEOS = `https://api.themoviedb.org/3/movie/${id}/videos?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;

    const [detail, setDetail] = useState({});
    const [genres, setGenres] = useState([]);
    const [credits, setCredits] = useState([]);
    const [country, setCountry] = useState("");
    const [videoKey, setVideoKey] = useState("");
    const history = useHistory();

    useEffect(() => {
        axios.get(URL).then((res) => {
            setDetail(res.data);
            setGenres(res.data.genres);
            if (res.data.production_countries.length > 0) {
                setCountry(res.data.production_countries[0].name);
            }
        });
        axios.get(URL_CREDITS).then((res) => {
            setCredits(res.data.cast);
        });
        axios.get(URL_VIDEOS).then((res) => {
            const trailer = res.data.results.find((video) => video.type === 'Trailer');
            if (trailer) {
                setVideoKey(trailer.key);
            }
        });
    }, [URL, URL_CREDITS, URL_VIDEOS]);
    

    return (
        <React.Fragment>
            <div className="details-container">
                <Logo />
                <div className="movie_card" id="bright">
                    <div className="backdrop_section">
                        <img
                            className="backdrop_image"
                            src={`http://image.tmdb.org/t/p/original${detail.backdrop_path}`}
                            alt="backdrop"
                        />
                        <h1 className="movie_title">{detail.title}</h1>
                    </div>
                    <div className="info_section">
                        <div className="movie_header">
                            <div className="movie_left">
                                <img
                                    className="locandina"
                                    src={`http://image.tmdb.org/t/p/original${detail.poster_path}`}
                                    alt="poster"
                                />
                            </div>
                            <div className="movie_right">
                                <p className="info-row"><span className="label">Year:</span> <span className="value">{detail.release_date}</span></p>
                                <p className="info-row">
                                    <span className="label">Type:</span>
                                    <span className="value">
                                        {genres.slice(0, 2).map((g) => g.name).join(", ")}
                                    </span>
                                </p>


                                <p className="info-row"><span className="label">Duration:</span> <span className="value">{detail.runtime} min</span></p>
                                <p className="info-row"><span className="label">Country:</span> <span className="value">{country}</span></p>
                            </div>
                        </div>

                        <div className="movie_desc">
                            <div className="button_container">
                                <button className="desc_button download_button">Download</button>
                                <button className="desc_button watch_button">Watch</button>
                            </div>
                            <p className="text">{detail.overview}</p>
                            <div className="video-container">
    {videoKey && (
        <iframe
            title="movie-trailer"
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoKey}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    )}
</div>
                        </div>
                        <p className="textActor">Acteurs ayant joué dans ce film</p>
                        <ul className="actors_desc">
                            {credits
                                .slice(0, 5)
                                .map((c) => {
                                    return (
                                        <Actor
                                            actor={c}
                                            key={c.id}
                                            onClickActor={() => history.push("/actorDetails", { id: c.id })} />
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="subscribe-banner">
                <div className="subscribe-text">
                    <h1>Stay in the cinematic loop!</h1>
                    <div className="centered-text-movi">
                        Be the first to know about the latest blockbusters,<br></br>
                        exclusive interviews with your favorite stars, and<br></br>
                        behind-the-scenes insights.
                        Subscribe to our movie<br></br>
                        newsletter today and get a front-row seat to all the <br></br>
                        action. Don't miss out on
                        the buzz! Sign up now and<br></br>
                        let the movie magic come to you.
                        <h1>Subscribe to our newsletter</h1>
                    </div>
                </div>
                <div className="subscribe-signup">
                    <input type="email" id="subscribeEmail" required />
                    <button>SUBSCRIBE</button>
                </div>
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default MovieDetails;