import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useHistory } from "react-router-dom";

const PopularMovies = () => {
    const [popular, setPopular] = useState([]);
    const [currentMovieIndex, setCurrentMovieIndex] = useState(0);
    const history = useHistory();
// ...

useEffect(() => {
    axios.get("https://api.themoviedb.org/3/movie/popular?api_key=2a9dbf4a6b379d6903604e787f490137&language=fr-FR&page=1")
        .then((res) => {
            let results = [...res.data.results];
            // Filtrer les films d'action de 2023
            let actionMovies2023 = results.filter(movie => {
                return movie.release_date.startsWith('2023') && movie.genre_ids.includes(28);
            });

            Promise.all(actionMovies2023.map((movie) => {
                return axios.get(`https://api.themoviedb.org/3/movie/${movie.id}?api_key=2a9dbf4a6b379d6903604e787f490137&language=fr-FR&page=1`)
            }))
                .then((additionalRes) => {
                    for (let i = 0; i < actionMovies2023.length; i++) {
                        actionMovies2023[i] = {
                            ...actionMovies2023[i],
                            genre_names: additionalRes[i].data.genres.map(genre => genre.name),
                        };
                    }
                    setPopular(actionMovies2023.slice(0, 5)); // Prendre les 5 premiers films d'action de 2023
                });
        });
}, []);

// ...


    // Change movie after some time
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentMovieIndex((currentMovieIndex + 1) % popular.length);
        }, 2000);

        return () => clearInterval(timer);
    }, [currentMovieIndex, popular]);

    // Render the component
    return (
        <div className="popularMovies">
            <div className="arrowLeft" onClick={() => setCurrentMovieIndex((currentMovieIndex - 1 + popular.length) % popular.length)}></div> {/* Ajoutez cette ligne */}
            {popular.length > 0 &&
                <div>
                    <Movie 
                        movie={popular[currentMovieIndex]} 
                        isCarousel={true}
                        onClickMovie={() => history.push('/movieDetails', { id : popular[currentMovieIndex].id })}
                    />
                    <div className="movieNumbers">
                        {popular.map((movie, index) => (
                            <span 
                                key={movie.id} 
                                className={index === currentMovieIndex ? 'active' : ''}
                                onClick={() => {
                                    history.push('/movieDetails', { id : movie.id})
                                }}
                            >
                                {index + 1}
                            </span>
                        ))}
                    </div>
                </div>
            }
            <div className="arrowRight" onClick={() => setCurrentMovieIndex((currentMovieIndex + 1) % popular.length)}></div> {/* Ajoutez cette ligne */}
        </div>
    );
    
};

export default PopularMovies;
