// Importation de React
import React from "react";

// Définition du composant Register
const Register = () => {
    return (
        // Création du formulaire d'inscription
        <form>
            <label htmlFor="firstName">Prénom:</label>
            <input type="text" id="firstName" required />
            <label htmlFor="lastName">Nom de famille:</label>
            <input type="text" id="lastName" required />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" required />
            <label htmlFor="password">Mot de passe:</label>
            <input type="password" id="password" required />
            <input type="submit" value="Inscription" />
        </form>
    )
}

// Exportation du composant Register
export default Register;
