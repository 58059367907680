import React from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

const About = () => {
    return (
        <div className="about-page">
            <div>
                <Logo />
                <Navigation />
            </div>
            <div className="about-header">
                About
            </div>
            <div className="about-content">
                <div className="img-box">
                    <img src="https://i.ibb.co/DfxrWcf/20190515-145016-IMG-1650.png" alt="Robert Courville" style={{ width: '150px', height: '175px', marginRight: '5px' }} />
                    <img src="https://i.ibb.co/cLk8F1Q/Chbabiby.png" alt="Waseem Chbabiby" style={{ width: '150px', height: '175px' }} />
                </div>
               <h3>Introducing the Creative Forces Behind Adrenaline Cinema: The Ultimate Movie Experience</h3>
                <p>Introducing the Creative Forces Behind Adrenaline Cinema: The Ultimate Movie Experience

                    Welcome to Adrenaline Cinema, where the world of movies comes alive with electrifying excitement! Behind this groundbreaking movie website are two remarkable individuals who have seamlessly blended their talents and expertise to create an unparalleled cinematic journey. Allow us to introduce the visionary minds that have brought Adrenaline Cinema to life: Robert Courville, the artistic virtuoso, and Waseem Chbabiby, the technical wizard.<br></br><br></br>

                    Robert Courville, the brilliant mastermind behind Adrenaline Cinema's captivating design, has poured his heart and soul into crafting a visually stunning platform that will leave you breathless. With an unwavering commitment to design excellence, Robert has meticulously curated an immersive experience, ensuring every pixel ignites the thrill of the silver screen. From the mesmerizing color schemes to the meticulously crafted layouts, Robert's expertise in design has set the stage for an unforgettable movie adventure.<br></br><br></br>

                    Working alongside Robert, Waseem Chbabiby serves as the technical genius responsible for seamlessly integrating the various components of Adrenaline Cinema. Through his unparalleled programming skills and deep understanding of web development, Waseem has transformed Robert's visionary designs into a functional masterpiece. His commitment to precision and attention to detail guarantees a smooth user experience, allowing movie enthusiasts to explore Adrenaline Cinema effortlessly.<br></br><br></br>

                    However, it is in their joint venture as programming partners where Robert Courville and Waseem Chbabiby truly shine. The synergy they share is undeniable as they combine their talents, expertise, and passion for movies to bring Adrenaline Cinema to life. Together, they have conquered countless challenges, pushing the boundaries of what a movie website can achieve. Their shared dedication and collaborative spirit have resulted in a platform that stands at the forefront of cinematic innovation.<br></br><br></br>

                    Adrenaline Cinema represents the culmination of Robert Courville's unwavering commitment to his craft as a designer and Waseem Chbabiby's technical prowess as an integration specialist. It is a testament to their shared vision, where design, integration, and programming expertise converge to create an unparalleled movie-watching experience. Through their tireless efforts, they have birthed a platform that will captivate your senses, transporting you to the adrenaline-fueled world of cinema.

                    Join us as we celebrate the artistic brilliance of Robert Courville and the technical mastery of Waseem Chbabiby. Together, they have created Adrenaline Cinema, a movie website that redefines how we experience the magic of film. Prepare to be swept away on an exhilarating cinematic journey, where passion meets technology and where Adrenaline Cinema reigns supreme.
					</p>
            </div>
            <div className="subscribe-banner">
                <div className="subscribe-text">
                    <h1>Stay in the cinematic loop!</h1>
                    <div className="centered-text-Acceuil">
                        Be the first to know about the latest blockbusters,<br></br>
                        exclusive interviews with your favorite stars, and<br></br>
                        behind-the-scenes insights.
                        Subscribe to our movie<br></br>
                        newsletter today and get a front-row seat to all the <br></br>
                        action. Don't miss out on
                        the buzz! Sign up now and<br></br>
                        let the movie magic come to you.
                        <h1>Subscribe to our newsletter</h1>
                    </div>
                </div>
                <div className="subscribe-signup">
                    <input type="email" id="subscribeEmail" required />
                    <button>SUBSCRIBE</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;
