import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../components/Logo";
import Footer from "../components/Footer";

const ActorDetails = (props) => {
    const id = props.location.state.id;
    const URL = `https://api.themoviedb.org/3/person/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const [detail, setDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [yearsOfExperience, setYearsOfExperience] = useState(0);
    const [numberOfMovies, setNumberOfMovies] = useState(0);

    useEffect(() => {
        axios
            .get(URL)
            .then((res) => {
                setDetail(res.data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`)
        .then((res) => {
            let earliestYear = new Date().getFullYear();
            let totalMovies = 0;

            res.data.cast.forEach((movie) => {
                const releaseYear = new Date(movie.release_date).getFullYear();
                if (releaseYear < earliestYear) {
                    earliestYear = releaseYear;
                }
                totalMovies++;
            });

            setYearsOfExperience(new Date().getFullYear() - earliestYear);
            setNumberOfMovies(totalMovies);
        })
        .catch((error) => {
            console.log(error);
        });
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <React.Fragment>
            <div className="details-container">
                <Logo />
                
                <div className="movie_card" id="bright">
                    <div className="centered-text">{detail.name}</div>
                    <div className="info_section">
                        <div className="actor-details">
                            <img
                                className="locandina-page"
                                src={`http://image.tmdb.org/t/p/original${detail.profile_path}`}
                                alt="poster"
                            />
                            <div className="actor-info">
                                <h6>
                                    <span className="label">Born:</span>
                                    <br />
                                    <span className="born-value">{detail.birthday} in {detail.place_of_birth}</span>
                                </h6>

                                <h6>
                                    <span className="label">Age:</span> {new Date().getFullYear() - new Date(detail.birthday).getFullYear()}
                                </h6>
                                <h6><span className="label">Profession:</span> Actor</h6>
                                <h6><span className="label">Country:</span> USA</h6>
                                <button className="actor-detail-button">{yearsOfExperience} Years of Experience</button>
                                <button className="actor-detail-button">{numberOfMovies} Movies/Series</button>
                            </div>
                        </div>
                        <div className="movie_desc-page">
                            <h2 className="biography-title">BIOGRAPHY</h2>
                            <p className="text-page">{detail.biography}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subscribe-banner">
                <div className="subscribe-text">
                    <h1>Stay in the cinematic loop!</h1>
                    <div className="centered-text-movi">
                        Be the first to know about the latest blockbusters,<br></br>
                        exclusive interviews with your favorite stars, and<br></br>
                        behind-the-scenes insights.
                        Subscribe to our movie<br></br>
                        newsletter today and get a front-row seat to all the <br></br>
                        action. Don't miss out on
                        the buzz! Sign up now and<br></br>
                        let the movie magic come to you.
                        <h1>Subscribe to our newsletter</h1>
                    </div>
                </div>
                <div className="subscribe-signup">
                    <input type="email" id="subscribeEmail" required />
                    <button>SUBSCRIBE</button>
                </div>
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default ActorDetails;