// Importation de React
import React from "react";

// Définition du composant Login
const Login = () => {
    return (
        // Création du formulaire de connexion
        <form>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" required />
            <label htmlFor="password">Mot de passe:</label>
            <input type="password" id="password" required />
            <input type="submit" value="Connexion" />
        </form>
    )
}

// Exportation du composant Login
export default Login;
