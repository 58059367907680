

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const Carousel = ({ category }) => {
  const [movies, setMovies] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const fetchMovies = async () => {
      let response;
      try {
        if (isNaN(category)) {
          
          response = await fetch(`https://api.themoviedb.org/3/movie/${category}?api_key=2a9dbf4a6b379d6903604e787f490137`);
        } else {
          
          response = await fetch(`https://api.themoviedb.org/3/discover/movie?api_key=2a9dbf4a6b379d6903604e787f490137&with_genres=${category}`);
        }

        const data = await response.json();
        setMovies(data.results);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, [category]);

  useEffect(() => {
    if (carouselRef.current && movies.length) {
      const carouselWidth = carouselRef.current.offsetWidth;
      carouselRef.current.style.transform = `translateX(-${currentIndex * carouselWidth}px)`;
    }
  }, [currentIndex, movies]); 

  const handleMovieClick = (movieId) => {
    // Rediriger l'utilisateur vers la page des détails du film lorsque le carrousel est cliqué
    history.push('/movieDetails', { id: movieId });
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= movies.length || newIndex >= (movies.length / 5)) {
        return prevIndex;
      }
      return newIndex;
    });
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return prevIndex;
      }
      return newIndex;
    });
  };

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex >= (movies.length / 5) - 1;

  return (
    <div className="carousel">
      <button className="carousel-prev" onClick={goToPrevSlide} disabled={isPrevDisabled}>
        &lt;
      </button>
      <div className="carousel-content" ref={carouselRef}>
        {movies && movies.map((movie, index) => (
          <div
            key={movie.id}
            className={`movie-card ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleMovieClick(movie.id)}
          >
            <img
              src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`}
              alt={movie.title}
              className={index === currentIndex ? 'center' : ''}
            />
            <h3>{movie.title}</h3>
          </div>
        ))}
      </div>
      <button className="carousel-next" onClick={goToNextSlide} disabled={isNextDisabled}>
        &gt;
      </button>
    </div>
  );
};

export default Carousel;

