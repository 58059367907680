// Import necessary libraries
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; 
import Logo from "../components/Logo";
import Footer from "../components/Footer";

// Define ActorPage component
const ActorPage = () => {
  // Initialize necessary state variables
  const [actors, setActors] = useState([]);
  
  // Fetch actor data
  useEffect(() => {
    const fetchActors = async (page) => {
      const URL = `https://api.themoviedb.org/3/person/popular?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&page=${page}`;
      
      try {
        const res = await axios.get(URL);
        return res.data.results;
      } catch (error) {
        console.error(error);
      }
    }

    const fetchAllActors = async () => {
      const allActors = [];
      
      for(let i = 1; i <= 3; i++) {
        const actors = await fetchActors(i);
        allActors.push(...actors);
      }

      setActors(allActors.slice(0, 50));
    }

    fetchAllActors();

  }, []);

  // Return component
  return (
    <React.Fragment>
      
      
      <div className="details-container">
        <Logo />
        <div className="actorListContainer">
          {actors.map((actor) => (
            <Link
              to={{
                pathname: `/actorDetails/${actor.id}`,
                state: { id: actor.id },
              }}
              className="actor"
              key={actor.id}
            >
              <img
                src={`http://image.tmdb.org/t/p/original${actor.profile_path}`}
                alt={actor.name}
              />
              <div className="actorName">{actor.name}</div>
            </Link>
          ))}
        </div>
        <div className="subscribe-banner">
                <div className="subscribe-text">
                    <h1>Stay in the cinematic loop!</h1>
                    <div className="centered-text-movi">
                        Be the first to know about the latest blockbusters,<br></br>
                        exclusive interviews with your favorite stars, and<br></br>
                        behind-the-scenes insights.
                        Subscribe to our movie<br></br>
                        newsletter today and get a front-row seat to all the <br></br>
                        action. Don't miss out on
                        the buzz! Sign up now and<br></br>
                        let the movie magic come to you.
                        <h1>Subscribe to our newsletter</h1>
                    </div>
                </div>
                <div className="subscribe-signup">
                    <input type="email" id="subscribeEmail" required />
                    <button>SUBSCRIBE</button>
                </div>
            </div>
        {/* Moving the Footer component here to push it to the bottom */}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ActorPage;
